// import Vue from "vue";
// import Component from "vue-class-component";
import { Vue,Component,Prop, Watch } from "vue-property-decorator";

@Component
export default class TableTS extends Vue {
    // 数据列表
    @Prop({ type: Array, required: true, default: [] }) private data!: Array<any>;
    // 需要展示的列 === prop：列数据对应的属性，label：列名，align：对齐方式，width：列宽, // table 表格的控制参数
    @Prop({ type: Array, required: true, default: [] }) private columns!: Array<any>;
    //是否加载
    @Prop({ type: Boolean, required: false }) private isLoading = false;
    //是否在表尾显示合计行
    @Prop({ type: Boolean, required: false }) private isShowSummary = false;
    //空数据文字提示
    @Prop({ type: String, required: false, default: "暂无数据" }) private emptyText!: string;
    //是否带边框
    @Prop({ type: Boolean, required: false, default: false }) private border!: boolean;

    tableData: Array<any> = this.data;
    pageIndex = 1;
    multipleSelection: Array<any> = [];// 多行选中
    isLoad: boolean = this.isLoading;

    @Watch('isLoading')
    public watchLoading(newVal: boolean) {
        this.isLoad = newVal;
    }

    // 多行选中
    handleSelectionChange(val: Array<any>) {
        this.multipleSelection = val;
        this.$emit("result", val);
    }
    //行点击
    handleRowClick(row: any, event: any, column: any) {
        const $table = this.$refs.mutipleTable;
        this.$emit("handleRowClick", row, event, column, $table);
    }
    //排序发生变化
    handleSortChange(column: any, key: any, order: any) {
        this.$emit("handleSortChange", column, key, order);
    }
}