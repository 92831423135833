import "./index.less";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  ChildAttachment,
  IChildAttachment,
} from "@/app/apiServices/childAttachment/public-api";
import { ApiUrl } from '../../../config';
import storage from "@/core/utils/storage";

@Component
export default class UploaderMaterialInformation extends Vue {
  @Prop({ type: Number }) childId?: number;
  @Prop({ type: Number }) fileType?: number;
  @Prop() fileDBInfo: any;
  @Prop({ type: Boolean }) disabled?: boolean;


  comFileType = 0;
  // comChildId = storage.GET_RegistrationInfo();
  comChildId = 0;
  fileData = { t: this.comFileType, cid: this.comChildId };
  fileList: IChildAttachment[] = [];
  uploadUrl = ApiUrl + "/Helper/FileSave";
  loading: any = {};

  disabledUpload = false;

  @Watch("childId", { immediate: true, deep: true })
  ChildIdWatch(val: number) {
    this.comChildId = val;
    this.fileData.cid = val
  }

  @Watch("fileType", { immediate: true, deep: true })
  fileTypeWatch(val: number) {
    this.comFileType = val;
    this.fileData.t = val
  }

  @Watch("fileDBInfo", { immediate: true, deep: true })
  fileDBInfoWatch(list: Array<ChildAttachment>) {
    this.fileList = list;
  }

  @Watch("disabled", {})
  disabledWatch(val: boolean) {
    this.disabledUpload = val;
  }

  get previewSrcList() {
    // const srcList: Array<string> = [];
    // this.fileList.forEach(u => {
    //   srcList.push(u.filePath);
    // });
    // return srcList;
    return this.fileList.map((item: IChildAttachment) => { return item.filePath })
  }

  //文件上传时的钩子
  handleProgrss() {
    this.loading = this.$loading({
      lock: true,
      text: "文件上传中，请耐心等待",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
  }

  //文件上传成功时的钩子
  handSucess(response: any) {
    this.loading.close();

    if (response.success) {
      this.$message.success('上传成功');

      response.result.result.forEach((resultData: any) => {
        const data = new ChildAttachment();
        data.childId = this.comChildId;
        data.displayName = resultData.item1;
        data.fileName = resultData.item2;
        data.filePath = resultData.item4;
        data.phyfilePath = resultData.item5;
        data.fileExtName = resultData.item3;
        data.fileType = Number(this.comFileType);
        data.isDelete = false;
        data.fileSource = 1;

        this.fileList.push(data);
      });

      this.$emit("fileChange", this.fileList, this.comFileType);
    } else {
      this.$message.error("上传失败！");
    }
  }

  //上传失败
  handError() {
    this.loading.close();
    this.$message.error("上传失败！");
  }

  //删除文件
  deleteFile(val: ChildAttachment) {
    this.$message.success('删除成功！');
    const findIndex = this.fileList.findIndex(/*  */
      (u) => u.fileName == val.fileName
    );
    if (findIndex > -1) {
      this.fileList.splice(findIndex, 1);
    }
    this.$emit("fileDelete", val, this.fileList, this.comFileType);
  }
}