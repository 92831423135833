import "./index.less";
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class DescriptionsTS extends Vue {
    //标题
    @Prop({ type: String, required: false, default: "" }) private title!: string;
    // 数据列表
    @Prop({ type: Object, required: true, default: {} }) private data!: any;
    // 需要展示的列 === prop：列数据对应的属性，label：列名// 描述列表 item的控制参数
    @Prop({ type: Array, required: true, default: [] }) private columns!: Array<any>;
    //一行 Descriptions Item 的数量
    @Prop({ type: Number, required: false, default: 3 }) private column?: number;
    //是否带边框
    @Prop({ type: Boolean, required: false }) private border?: boolean;
    //排列的方向
    @Prop({ type: String, required: false }) private direction?: string;
    //是否显示冒号
    @Prop({ type: Boolean, required: false, default: true }) private colon?: boolean;

    comData = this.data;
    comColumns = this.columns;

    @Watch('data', { immediate: true, deep: true })
    public watchData(newVal: any) {
        this.comData = newVal;
    }

    @Watch('columns', { immediate: true, deep: true })
    public watchColumns(newVal: any) {
        this.comColumns = newVal;
    }
}