import './index.less';

import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { IGetZSPointTableGradePage, IPointTableDto, PointTableDto, SaveZSPointTableGradePage } from '@/app/apiServices/zsPointTableGrade/public-api';

@Component
export default class ComParentScoreTS extends Vue {

    @Prop() data!: IGetZSPointTableGradePage;

    comData = this.data;

    formModel = new SaveZSPointTableGradePage();

    viewData: PointTableDto[] = [];
    targetB = 0;

    //提示说明
    explainList: any[] = [
        { title: "直系亲属：", content: "指父母、祖父母和外祖父母" },
        { title: "父母稳定居住情况：", content: "需提供父母一方的“居住证”" },
        { title: "父母稳定就业情况：", content: "父母一方由劳动与社会保障部门开具的连续参加社会保险年限的证明或父母一方在街镇社区事务受理中心办妥的灵活就业登记证明(《就业失业登记证》上载明的“灵活就业登记情况”)" },
        { title: "幼儿证照情况：", content: "幼儿户籍证明" },
        // { title: "直技术职称或岗位证书：", content: "父母一方的技术职称或岗位证书（一般由人保局颁发）" },
        // { title: "教育背景：", content: "父母一方的学历证书" },
    ];

    @Watch('data')
    public watchData(newVal: IGetZSPointTableGradePage) {
        this.comData = newVal;
        this.initTableData(this.comData);
    }

    //获取总分
    get TotalScore() {
        let score = 0;
        this.viewData.forEach(u => {
            score += u.checkedTargetItemScore;
        });
        return score;
    }

    //合并单元格
    arraySpanMethod(column: any) {
        //合并列
        if (column.columnIndex == 0) {

            if (column.rowIndex == 0) {
                return this.getSpanArr(column.row, "projectName", column.rowIndex);
            }
            else {
                return [0, 0]
            }
        }
        if (column.columnIndex == 1) {
            if (column.rowIndex != this.viewData.length - 1) {
                return this.getSpanArr(column.row, "targetAName", column.rowIndex);
            }
        }
    }

    //获取行数
    getSpanArr(row: IPointTableDto, field: string, rowIndex: number) {
        let startRow = 0;
        let count = 0;
        let hasFind = false;
        this.viewData.forEach((item: PointTableDto, index: number) => {
            const data = item as unknown as IPointTableDto;
            if (data[field] == row[field]) {
                if (!hasFind) {
                    startRow = index;
                }

                hasFind = true;
                count++;
            }
            else
                return [1, 1];
        });

        return hasFind && startRow == rowIndex ? [count, 1] : [0, 0];
    }

    //指标名称change事件
    targetBChange(id: number) {
        this.viewData.forEach(item => {
            if (item.targetBId == id) {
                item.targetBIsDisabled = false;
            }
            else {
                if (item.targetBIsRadio) {
                    item.targetBIsDisabled = true;
                    item.checkedTargetItemId = 0;
                    item.checkedTargetItemScore = 0;
                }
            }
        });
    }

    //指标项change事件
    targetItemsChange(itemId: number) {
        const findRow = this.viewData.find(u => u.checkedTargetItemId == itemId);
        if (findRow) {
            const findItem = findRow.targetItems.find(u => u.itemId == itemId);
            if (findItem) {
                findItem.isChecked = true;
                findRow.checkedTargetItemScore = findItem.score;
            }
        }
    }

    //初始化表单数据
    initTableData(data: IGetZSPointTableGradePage) {
        //第一层 
        data.pointTableDtos.forEach(first => {

            //第二层
            first.targetAs.forEach(targetA => {

                //第三层
                targetA.targetBs.forEach(targetB => {
                    const data = new PointTableDto();
                    data.projectName = first.name;
                    data.targetAName = targetA.name;
                    data.targetBId = targetB.id;
                    data.targetBName = targetB.name;
                    data.targetBIsChecked = targetB.isChecked;
                    data.targetBIsRadio = targetB.isRadio;
                    data.targetBIsDisabled = targetB.isDisabled;
                    data.checkedTargetItemId = targetB.checkedTargetItemId;
                    data.checkedTargetItemScore = targetB.checkedTargetItemScore;
                    data.targetItems = targetB.targetItems;

                    if (data.targetBIsChecked) {
                        this.targetB = data.targetBId;
                    }
                    if (data.targetBIsRadio && !data.targetBIsChecked) {
                        data.targetBIsDisabled = true;
                    }
                    this.viewData.push(data);
                })
            })
        });
    }

    //检查数据
    checkFormModel() {
        if (this.targetB == 0) {
            return false;
        }
        let result = true;
        this.viewData.forEach(item => {
            if (!item.targetBIsDisabled && item.checkedTargetItemId == 0) {
                result = false;
            }
        });
        return result;
    }

    //设置提交数据
    getFormModel() {
        this.formModel.parentScore = this.TotalScore;
        this.viewData.forEach(item => {
            //存在选项值
            if (item.checkedTargetItemId != 0) {
                const data = {
                    id: item.targetBId,
                    itemId: item.checkedTargetItemId
                }
                this.formModel.parentTargets.push(data);
            }
        });
        return this.formModel;
    }
}