export const GradeEnum = [
    {
        name: "托班",
        value: "SZNJ_tb"
    },
    {
        name: "小班",
        value: "SZNJ_xb"
    },
    {
        name: "中班",
        value: "SZNJ_zb"
    },
    {
        name: "大班",
        value: "SZNJ_db"
    }
]

function GradeName(val: string) {
    const find = GradeEnum.find(u => u.value == val);
    return find ? find.name : '';
}

function GradeLabel(val: string) {
    switch (val) {
        case 'SZNJ_tb':
            return "入托需求登记点";
        case 'SZNJ_xb':
            return "小班报名点";
        default:
            return "指定报名点";
    }
}

export {
    GradeName,
    GradeLabel
}