import './index.less';

import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Descriptions, Upload } from "@/app/components";
import { FullChildRegInfoDto, SaveRefChildGardenDto, zsRefChildGardenService } from '@/app/apiServices/zsRefChildGarden/public-api';
import { AttachmentPage, IChildAttachment } from '@/app/apiServices/childAttachment/public-api';
import { tbAdjustMessage } from '@/config/message';
import { GradeLabel } from '@/core/utils/enum/grade';
@Component({
    components: { Descriptions, Upload }
})
export default class ChildDetailToThisTS extends Vue {
    @Prop() data?: SaveRefChildGardenDto;
    @Prop() childId?: number;
    formModel = new FullChildRegInfoDto();

    //报名园所信息
    refChildGardenColumns = [
        {
            prop: "gardenName",
            label: "",
            slot: "gardenName"
        },
    ]

    //家庭成员信息
    familyColumns = [
        {
            prop: "relationship",
            label: "称谓",
        },
        {
            prop: "name",
            label: "姓名",
        },
        {
            prop: "cardType",
            label: "证件类型",
        },
        {
            prop: "cardNO",
            label: "证件号码",
        },
        {
            prop: "telephone",
            label: "手机",
        },
    ];

    //验证材料信息
    childAttachmentColumns: Array<any> = [];


    //获取报名园所label
    get gardenLabel() {
        return GradeLabel(this.formModel.gradeCode);
    }

    //获取报名园所名称
    get getRrefChildGarden() {
        
        const gardenNames: Array<string> = [];
        this.formModel.zsRefChildGardenDtos.forEach(item => {
            if (item.gardenID < 100) {
                gardenNames.push(item.gardenName);
            }
        })
        return gardenNames.join(' , ');
        // return this.formModel.zsRefChildGardenDtos.map(u => { if (u.gardenID < 100) return u.gardenName }).join(' , ');
        // return this.formModel.zsRefChildGardenDtos.map(u => {
        //     if (u.isAdjust == 1) {
        //         return `${u.gardenName}(${tbAdjustMessage})`
        //     }
        //     else {
        //         return u.gardenName
        //     }
        // }).join(' , ');
    }

    get getIsAdjust() {
        let result = undefined;
        if (this.formModel.gradeCode !== 'SZNJ_tb')
            return result;
        this.formModel.zsRefChildGardenDtos.forEach(item => {
            if (item.isAdjust == 1) {
                result = `${tbAdjustMessage}`
            }
        });
        return result;
    }

    //是否显示户主与幼儿关系
    isShowRelationOfDomicile() {
        if (this.formModel.gradeCode === 'SZNJ_xb' &&
            this.formModel.zsChildBaseInfoDto.householdImmigrationDate != null) {
            const timeString = `${new Date().getFullYear()}-01-01`;
            const time = new Date(timeString).getTime() - (8 * 3600 * 1000);   //手动减8小时
            return new Date(this.formModel.zsChildBaseInfoDto.householdImmigrationDate).getTime() >= time;
        }
        return false;
    }

    //获取附件放大预览列表
    getChildAttachmentSrcList(childAttachmentDtos: Array<IChildAttachment>) {
        return childAttachmentDtos.map((item: IChildAttachment) => { return item.filePath });
    }

    setColumns() {
        // 验证材料
        this.formModel.childAttachmentInfoDto.childAttachmentGroupDtos.forEach((item: AttachmentPage) => {
            this.childAttachmentColumns.push({
                prop: "fileTypeName_" + item.fileType,
                label: item.fileTypeName,
                slot: "fileTypeName_" + item.fileType
            })
        })
    }

    getFullChildRegInfo() {
        zsRefChildGardenService.getFullChildRegInfo(this.data as SaveRefChildGardenDto).then((res: FullChildRegInfoDto) => {
            if (res.verifyResult.success) {
                this.formModel = res;
                //初始化展示列信息
                this.setColumns();
            }
            else {
                this.$toast({ message: res.verifyResult.message });
            }
        })
    }

    getDetailChildRegInfo(childId: number) {
        zsRefChildGardenService.getDetailChildRegInfo(childId).then((res: FullChildRegInfoDto) => {
            if (res.verifyResult.success) {
                this.formModel = res;
                //初始化展示列信息
                this.setColumns();
            }
            else {
                this.$toast({ message: res.verifyResult.message });
            }
        })

    }

    async viewLoad() {
        //报名确认页
        if (this.data) {
            this.getFullChildRegInfo();
        }
        //详情页
        if (this.childId) {
            this.getDetailChildRegInfo(this.childId);
        }
    }

    mounted(): void {
        this.viewLoad();
    }
}